import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  const topOffset = "7.1875rem";
  return createStyles({
    root: {
      marginLeft: "-3.8125rem",
      width: "17.25rem",
      marginRight: "auto",
      display: "block",
      // transform: "matrix(1, -0.07, 0.07, 1, 0, 0)",
      transform: "translateY(-7.1875rem)",
      marginBottom: `calc(0.9375rem - ${topOffset})`,
      [flexBreakpoint]: {
        display: "none",
      },
    },
  });
};

const SingleFruitImage = ({ classes }) => {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "miracle-berry" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const image = getImage(file);
  return (
    <GatsbyImage image={image} className={classes.root} objectFit="contain" />
  );
};

export default withStyles(styles)(SingleFruitImage);
