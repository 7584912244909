import React from "react";
import { Button, createStyles, withStyles } from "@material-ui/core";
import { Link } from "gatsby";
import { getExpandBreakpoint } from "../styles";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      textDecoration: "none",
      padding: "2rem 0",
      borderBottom: "1px solid black",
      display: "block",
      [expandBreakpoint]: {
        marginBottom: 0,
        borderBottom: 0,
        padding: 0,
      },
      "& + &": {
        [expandBreakpoint]: {
          marginLeft: "2rem",
        },
        [theme.breakpoints.up("xl")]: {
          marginLeft: "3.75rem",
        },
      },
    },
    button: {
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: "1.6875rem",
      padding: 0,
      justifyContent: "flex-start",
      [expandBreakpoint]: {
        minWidth: 0,
        color: "white",
      },
    },
  });
};

const NavItem = ({ classes, href, ...other }) => {
  return (
    <Link className={classes.root} to={href}>
      <Button className={classes.button} {...other} />
    </Link>
  );
};

export default withStyles(styles)(NavItem);
