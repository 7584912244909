import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    title: {
      fontWeight: 600,
      fontSize: "1.25rem",
      marginBottom: "0.625rem",
      [md]: {
        marginBottom: "1.125rem",
        fontSize: "1.875rem",
      },
    },
    description: {
      fontSize: "0.6875rem",
      lineHeight: "1.375rem",
      fontWeight: "normal",
      maxWidth: "36.875rem",
      [md]: {
        fontSize: "1.125rem",
        lineHeight: "2.1875rem",
      },
    },
  });
};

const Body = ({ classes, title, description }) => {
  return (
    <div>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
};

export default withStyles(styles)(Body);
