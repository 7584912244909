import * as React from "react";

function SvgLeftArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.973 19.194"
      {...props}
    >
      <path d="M3.307 9.594l7.262-7.257A1.372 1.372 0 008.626.4L.399 8.623a1.369 1.369 0 00-.04 1.891l8.262 8.279a1.372 1.372 0 001.943-1.937z" />
    </svg>
  );
}

export default SvgLeftArrow;
