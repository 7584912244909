import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import Image from "./Image";

import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  const marginX = "auto";
  return createStyles({
    root: {
      display: "block",
      marginLeft: marginX,
      marginRight: marginX,
      [flexBreakpoint]: {
        display: "none",
      },
    },
  });
};

const MobileImage = ({ classes }) => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(name: { eq: "berry-halves-full" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );
  return <Image imageFile={file} classes={classes} />;
};

export default withStyles(styles)(MobileImage);
