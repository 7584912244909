import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";

import { getShowButtonBreakpoint } from "./styles";

const styles = (theme) => {
  const showButtonBreakpoint = getShowButtonBreakpoint(theme);
  return createStyles({
    root: {
      fontSize: "1.125rem",
      borderBottom: "2px solid black",
      fontWeight: 600,
      padding: 0,
      textTransform: "uppercase",
      minWidth: 0,
      display: "none",
      [showButtonBreakpoint]: {
        display: "inline-block",
      },
    },
  });
};

const ReadMore = ({ classes, slug }) => {
  return (
    <Button className={classes.root} href={slug}>
      Read More
    </Button>
  );
};

export default withStyles(styles)(ReadMore);
