import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      padding: "2.125rem 0",
      backgroundColor: theme.palette.background.light,
      [theme.breakpoints.up("md")]: {
        padding: "4rem 0",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "7.5625rem 0",
      },
    },
  });
};

const SliderSectionSecondary = ({ classes, children }) => {
  return <section className={classes.root}>{children}</section>;
};

export default withStyles(styles)(SliderSectionSecondary);
