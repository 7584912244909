import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const styles = () => {
  return createStyles({
    root: {},
  });
};

const Image = ({ classes, imageFile }) => {
  const image = getImage(imageFile);
  return (
    <GatsbyImage image={image} className={classes.root} objectFit="contain" />
  );
};

export default withStyles(styles)(Image);
