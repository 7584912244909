import React from "react";
import { Helmet } from "react-helmet";

import FeaturedRecipes from "./FeaturedRecipes";
import Hero from "./Hero";
import Lead from "./Lead";
import MiracleBerry from "./MiracleBerry";
import Section3 from "./Section3";
import VideoSection from "./VideoSection";
import HowToUseRichberry from "./HowToUseRichberry";
import Blog from "./Blog";
import Testimonials from "./Testimonials";
import LetsBeSocial from "./LetsBeSocial";
import OurProducts from "./OurProducts";
import ShopLinks from "./ShopLinks";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Richberry</title>
      </Helmet>
      <Hero />
      <Lead />
      <MiracleBerry />
      <VideoSection />
      <Section3 />
      <FeaturedRecipes />
      <HowToUseRichberry />
      <Blog />
      <Testimonials />
      <LetsBeSocial />
      <OurProducts />
      <ShopLinks />
    </>
  );
};

export default Home;
