import { createStyles, withStyles } from "@material-ui/core";

import Side from "./Side";

const styles = () => {
  return createStyles({
    root: {
      right: 0,
    },
  });
};

export default withStyles(styles)(Side);
