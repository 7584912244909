import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Center from "./Center";
import Left from "./Left";
import Right from "./Right";
import { getFlexBreakpoint } from "../styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      position: "relative",
      minHeight: "20.3125rem",
      marginBottom: "2rem",
      maxWidth: "21.1875rem",
      margin: "0 auto 2rem",
      "&::after": {
        width: "100%",
        maxWidth: "21.1875rem",
        height: "16.25rem",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF",
        border: "1px solid #707070",
        borderRadius: "21.6875rem",
        opacity: 0.25,
        filter: "blur(3.125rem)",
        content: "''",
        [theme.breakpoints.up("xl")]: {
          maxWidth: "57.9375rem",
          height: "41.8125rem",
        },
      },
      [flexBreakpoint]: {
        margin: 0,
        width: "55%",
        maxWidth: "46.875rem",
        marginRight: "2rem",
        minHeight: "22.9456rem",
        flexGrow: 1,
        flexShrink: 0,
      },
      [theme.breakpoints.up("lg")]: {
        width: "62.5%",
        maxWidth: "100%",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "8.9375rem",
        width: "57.625rem",
        minHeight: "54.9375rem",
      },
    },
  });
};

const Images = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Left />
      <Center />
      <Right />
    </div>
  );
};

export default withStyles(styles)(Images);
