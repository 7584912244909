import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      padding: "1.25rem 1.1731rem",
      backgroundColor: "white",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.5625rem",
        padding: "3.125rem 2.125rem 3.75rem",
      },
    },
    title: {
      fontSize: "0.8125rem",
      marginBottom: "0.625rem",
      textTransform: "none",
      fontWeight: 500,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.5625rem",
        marginBottom: "1.375rem",
      },
    },
    description: {
      fontSize: "0.6875rem",
      lineHeight: "1.125rem",
      marginBottom: "0.625rem",
      fontWeight: 400,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.125rem",
        lineHeight: "2.5rem",
      },
    },
  });
};

const Body = ({ classes, title, description, children }) => {
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
      {children}
    </div>
  );
};

export default withStyles(styles)(Body);
