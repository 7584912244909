import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Image from "./SocialMediaIllustration";

const styles = (theme) => {
  return createStyles({
    root: {
      display: "none",
      [theme.breakpoints.up("xl")]: {
        display: "block",
        position: "absolute",
        bottom: "0.9375rem",
        left: 0,
        width: "54.0625rem",
      },
    },
  });
};

const SocialMedia = ({ classes }) => {
  return <Image className={classes.root} />;
};

export default withStyles(styles)(SocialMedia);
