import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import ViewAllHeader from "../../../components/ViewAllHeader";
import ViewAll from "../../../components/ViewAllHeader/ViewAll";
import { getFlexBreakpoint } from "../styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      marginBottom: "2.125rem",
      [flexBreakpoint]: {
        marginBottom: "5.0625rem",
      },
    },
    text: {
      fontWeight: "normal",
      [flexBreakpoint]: {
        textAlign: "left",
      },
    },
  });
};

const Header = ({ classes }) => {
  return (
    <ViewAllHeader
      ViewAll={ViewAll}
      title="Featured Recipes"
      classes={{ root: classes.root }}
    >
      <Typography className={classes.text}>
        Looking for some tasty recipe inspiration? Browse through our collection
        of easy recipes.
      </Typography>
    </ViewAllHeader>
  );
};

export default withStyles(styles)(Header);
