import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";

import { getExpandBreakpoint } from "./styles";
import { xsUp } from "../../components/constants/breakpoints";

const styles = (theme) => {
  const buttonMargin = "1.25rem";
  const expandBreakpoint = getExpandBreakpoint(theme);
  const paddingX = {
    default: "1.75rem",
    xs: "2.3rem",
    sm: "1.5rem",
  };
  return createStyles({
    root: {
      padding: `1.3125rem ${paddingX.default}`,
      border: "1px solid #707070",
      backgroundColor: "white",
      marginBottom: buttonMargin,
      fontWeight: 500,
      fontSize: "0.8125rem",
      minWidth: 0,
      "&:nth-of-type(even)": {
        marginLeft: buttonMargin,
      },
      [xsUp]: {
        paddingLeft: paddingX.xs,
        paddingRight: paddingX.xs,
      },
      [expandBreakpoint]: {
        width: `calc(25% - ${buttonMargin})`,
        paddingLeft: paddingX.sm,
        paddingRight: paddingX.sm,
        "& + &": {
          marginLeft: buttonMargin,
        },
      },
      [theme.breakpoints.up("lg")]: {
        padding: "2.5rem 5rem",
        fontSize: "1.4375rem",
        border: "none",
        width: "auto",
        "& + &": {
          marginLeft: "2rem",
        },
      },
    },
  });
};

const ShopLink = ({ classes, ...other }) => {
  return <Button classes={classes} {...other} />;
};

export default withStyles(styles)(ShopLink);
