import { createStyles, withStyles } from "@material-ui/core";

import LearnMoreButton from "../../components/LearnMoreButton";
import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      [flexBreakpoint]: {
        justifyContent: "flex-start",
      },
    },
  });
};

export default withStyles(styles)(LearnMoreButton);
