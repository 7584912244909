import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { Link } from "gatsby";

import Image from "./Image";
import { getExpandBreakpoint } from "../styles";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      [expandBreakpoint]: {
        marginRight: "7rem",
      },
    },
    image: {
      width: "5.0625rem",
      [expandBreakpoint]: {
        width: "10.125rem",
      },
    },
  });
};

const Logo = ({ classes }) => {
  return (
    <Link to="/" className={classes.root}>
      <Image className={classes.image} />
    </Link>
  );
};

export default withStyles(styles)(Logo);
