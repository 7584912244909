import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Icon from "./StarIcon";

const styles = (theme) => {
  const iconSize = "0.875rem";
  const iconSizeMd = "1.25rem";
  return createStyles({
    root: {
      width: iconSize,
      height: iconSize,
      [theme.breakpoints.up("md")]: {
        width: iconSizeMd,
        height: iconSizeMd,
      },
      "& + &": {
        marginLeft: "0.3812rem",
      },
    },
  });
};

const Star = ({ classes }) => {
  return <Icon className={classes.root} />;
};

export default withStyles(styles)(Star);
