import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Section from "../../components/SliderSectionPrimary";
import Slider from "./Slider";
import Header from "./Header";
import ViewAllButton from "./ViewAllButton";

const OurProducts = () => {
  const {
    product1Image,
    product2Image,
    product3Image,
    product4Image,
  } = useStaticQuery(
    graphql`
      {
        product1Image: file(name: { eq: "product-1" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        product2Image: file(name: { eq: "product-2" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        product3Image: file(name: { eq: "product-3" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        product4Image: file(name: { eq: "product-4" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );

  const products = [
    {
      title: "1 Pack of 10 Freeze-dried Miracle Berry Halves (1g)",
      price: 9.99,
      image: product1Image,
    },
    {
      title: "2 Packs of 10 Freeze-dried Miracle Berry Halves (1g)",
      price: 19.69,
      image: product2Image,
    },
    {
      title: "1 Pack of 50 Freeze-dried Miracle Berry Halves (8g)",
      price: 39.99,
      image: product3Image,
    },
    {
      title: "1 Pack of 100 Freeze-dried Miracle Berry Halves (16g)",
      price: 69.99,
      image: product4Image,
    },
  ];

  return (
    <Section>
      <Header />
      <Slider products={products} />
      <ViewAllButton />
    </Section>
  );
};

export default OurProducts;
