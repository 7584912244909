import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { Link } from "gatsby";

export const paddingX = "0.7813rem";

const styles = (theme) => {
  return createStyles({
    root: {
      paddingLeft: paddingX,
      paddingRight: paddingX,
      paddingBottom: "2rem",
      textDecoration: "none",
      color: "black",
      display: "block",
      [theme.breakpoints.up("xl")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    container: {
      boxShadow: "10px 10px 30px #00000029",
      width: "100%",
    },
  });
};

const Card = ({ classes, children, slug }) => {
  return (
    <Link className={classes.root} to={slug}>
      <div className={classes.container}>{children}</div>
    </Link>
  );
};

export { default as Body } from "./Body";
export { default as Image } from "./Image";

export default withStyles(styles)(Card);
