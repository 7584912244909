import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const styles = (theme) => {
  const marginX = "auto";
  const imageMargin = "1rem";
  return createStyles({
    root: {
      width: "11.375rem",
      marginBottom: "1.5625rem",
      display: "block",
      marginLeft: marginX,
      marginRight: marginX,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
        marginLeft: 0,
        marginRight: imageMargin,
        flexShrink: 0,
        width: "40%",
      },
      [theme.breakpoints.up("md")]: {
        width: "26.3125rem",
      },
    },
    imageRight: {
      [theme.breakpoints.up("sm")]: {
        order: 1,
        marginRight: 0,
        marginLeft: imageMargin,
      },
    },
  });
};

const Image = ({ classes, imageFile, stepNumber }) => {
  const getClasses = () => {
    const isEven = stepNumber % 2 === 0;
    const classNamesList = [classes.root];
    if (isEven) {
      classNamesList.push(classes.imageRight);
    }
    return classNamesList.join(" ");
  };

  const image = getImage(imageFile);

  return <GatsbyImage image={image} className={getClasses()} />;
};

export default withStyles(styles)(Image);
