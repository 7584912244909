import React from "react";
import {
  Drawer as BaseDrawer,
  Container,
  createStyles,
  withStyles,
} from "@material-ui/core";

import Links from "../Links";
import Close from "./Close";

const styles = () => {
  return createStyles({
    root: {
      backgroundColor: "white",
    },
    container: {
      padding: "2rem",
    },
  });
};

const Drawer = ({ classes, close, isOpen }) => {
  return (
    <BaseDrawer
      className={classes.root}
      onClose={close}
      open={isOpen}
      anchor="right"
      variant="temporary"
      fullScreen
    >
      <Container className={classes.container}>
        <Close close={close} />
        <Links />
      </Container>
    </BaseDrawer>
  );
};

export default withStyles(styles)(Drawer);
