import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import DesktopLearnMore from "./DesktopLearnMore";
import { getFlexBreakpoint } from "../styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      marginBottom: "2.125rem",
      textAlign: "center",
      [flexBreakpoint]: {
        marginBottom: 0,
        width: "50%",
        marginRight: "2rem",
        textAlign: "left",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "8.6875rem",
        width: "53.875rem",
      },
    },
    title: {
      marginBottom: "0.625rem",
      [theme.breakpoints.up("md")]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        textTransform: "none",
        marginBottom: "3.3125rem",
      },
    },
    text: {
      [flexBreakpoint]: {
        fontWeight: "normal",
        marginBottom: "3.3125rem",
      },
    },
  });
};

const Body = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Lorem Ipsum Dolor
      </Typography>
      <Typography className={classes.text}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper
        tristique ligulari id aliqual nulla in tincidunt felis itim nean auctor
        imperdiet lacus, vel elementum ums lorem laoreet ut. Nan molestie
        laoreet ante id pharetra it. Curabitur elit diam di, rhoncu faucibus
        dignissim nectu, vehiculi ut massa. Aliquam mattis
      </Typography>
      <DesktopLearnMore />
    </div>
  );
};

export default withStyles(styles)(Body);
