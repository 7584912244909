import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SliderSectionSecondary from "../../components/SliderSectionSecondary";
import Slider from "./Slider";
import Header from "./Header";
import ViewAllButton from "../../components/ViewAllButton";

const Blog = () => {
  const {
    post1: post1Image,
    post2: post2Image,
    post3: post3Image,
  } = useStaticQuery(graphql`
    {
      post1: file(name: { eq: "post-1" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      post2: file(name: { eq: "post-2" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      post3: file(name: { eq: "post-3" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const posts = [
    {
      title: "Lorem Ipsum Dolor",
      excerpt:
        "Lorem ipsum dolor sit amet, consectetur etis im adipiscing elit. Etiam auctor sit ametisi diam ne molestie. Phasellus auctor lect.",
      image: post1Image,
      fields: {
        slug: "/post-1/",
      },
    },
    {
      title: "Lorem Ipsum Dolor",
      excerpt:
        "Lorem ipsum dolor sit amet, consectetur etis im adipiscing elit. Etiam auctor sit ametisi diam ne molestie. Phasellus auctor lect.",
      image: post2Image,
      fields: {
        slug: "/post-2/",
      },
    },
    {
      title: "Lorem Ipsum Dolor",
      excerpt:
        "Lorem ipsum dolor sit amet, consectetur etis im adipiscing elit. Etiam auctor sit ametisi diam ne molestie. Phasellus auctor lect.",
      image: post3Image,
      fields: {
        slug: "/post-3/",
      },
    },
  ];

  return (
    <SliderSectionSecondary>
      <Header />
      <Slider posts={posts} />
      <ViewAllButton />
    </SliderSectionSecondary>
  );
};

export default Blog;
