import React from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";

import Slider from "./Slider";
import BaseSection from "../../components/SliderSectionPrimary";

const styles = (theme) => {
  return createStyles({
    title: {
      marginBottom: "1.75rem",
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        marginBottom: "4.5rem",
      },
    },
  });
};

const Testimonials = ({ classes }) => {
  const { file: image } = useStaticQuery(graphql`
    {
      file(name: { eq: "testimonial" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const testimonial = {
    title: "Lorem Ipsum",
    content:
      "Lorem ipsum dolor sit amet col consec adipisc elit. Morbisti feli risus, elem.",
    name: "Lorem, P",
    date: new Date().toString(),
    product: {
      image,
      name: "Product Name",
    },
  };

  const testimonials = [testimonial, testimonial, testimonial];

  return (
    <BaseSection>
      <Typography className={classes.title} variant="h2">
        Testimonials
      </Typography>
      <Slider testimonials={testimonials} />
    </BaseSection>
  );
};

export default withStyles(styles)(Testimonials);
