import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import Image from "./Image";

import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      display: "none",
      [flexBreakpoint]: {
        display: "block",
        width: "50%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "42.8125rem",
      },
    },
  });
};

const DesktopImage = ({ classes }) => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(name: { eq: "berry-halves-desktop" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );
  return <Image imageFile={file} classes={classes} />;
};

export default withStyles(styles)(DesktopImage);
