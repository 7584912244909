import { createStyles, withStyles } from "@material-ui/core";

import Title from "./Title";

const styles = (theme) => {
  return createStyles({
    root: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      lineHeight: "3.5rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "2.75rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "3.5rem",
        lineHeight: "4rem",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "4.375rem",
        lineHeight: "5.875rem",
      },
    },
  });
};

export default withStyles(styles)(Title);
