import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import BaseHeader from "../SliderSectionSecondary/Header";
import { getFlexBreakpoint } from "../../Home/FeaturedRecipes/styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      [flexBreakpoint]: {
        marginBottom: "2rem",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "5.0625rem",
      },
    },
    toolbar: {
      [flexBreakpoint]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1.375rem",
      },
    },
    title: {
      marginBottom: "0.625rem",
    },
  });
};

const Header = ({ classes, title, children, ViewAll }) => {
  return (
    <BaseHeader classes={{ root: classes.root }}>
      <div className={classes.toolbar}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <ViewAll />
      </div>
      {children}
    </BaseHeader>
  );
};

export default withStyles(styles)(Header);
