import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import BaseCard, {
  Image,
  Body,
} from "../../../../components/SliderSectionSecondary/Slider/Card";
import Stars from "./Stars";
import Time from "./Time";

const styles = () => {
  return createStyles({});
};

const Card = ({ classes, title, description, image }) => {
  return (
    <BaseCard>
      <Image imageFile={image}>
        <Time>Time</Time>
      </Image>
      <Body title={title} description={description}>
        <Stars />
      </Body>
    </BaseCard>
  );
};

export default withStyles(styles)(Card);
