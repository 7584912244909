import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { xsUp } from "../../../components/constants/breakpoints";

const styles = (theme) => {
  const imageWidthXs = "8.8125rem";
  const imageWidthMd = "21.5rem";
  const imageGap = "1.375rem";
  const imageGapMd = "3.125rem";

  const md = theme.breakpoints.up("md");

  return createStyles({
    root: {
      marginBottom: imageGap,
      width: `calc(50% - ${imageGap})`,
      [xsUp]: {
        width: imageWidthXs,
        height: imageWidthXs,
      },
      "&:nth-of-type(even)": {
        marginLeft: imageGap,
        [md]: {
          marginLeft: imageGapMd,
        },
      },
      [md]: {
        marginBottom: imageGapMd,
        width: imageWidthMd,
        height: imageWidthMd,
      },
    },
  });
};

const Item = ({ classes, imageFile }) => {
  const image = getImage(imageFile);
  return <GatsbyImage className={classes.root} image={image} />;
};

export default withStyles(styles)(Item);
