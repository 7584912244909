import React from "react";
import {
  Typography,
  createStyles,
  withStyles,
  Button,
} from "@material-ui/core";
import { Link } from "gatsby";

import CartIcon from "./CartIcon";
import { getExpandBreakpoint } from "../styles";
import { links } from "../../../constants/links";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  const badgeSize = "0.5625rem";
  const badgeSizeSm = "0.75rem";
  const sm = theme.breakpoints.up("sm");
  return createStyles({
    button: {
      padding: 0,
      marginRight: "0.9294rem",
      minWidth: 0,
      color: "white",
      [expandBreakpoint]: {
        marginRight: 0,
        paddingLeft: "1.8644rem",
      },
    },
    iconContainer: {
      position: "relative",
      [expandBreakpoint]: {
        marginRight: "0.9375rem",
      },
    },
    icon: {
      width: "1.125rem",
      height: "1rem",
      [sm]: {
        width: "1.25rem",
        height: "1.125rem",
      },
    },
    badge: {
      position: "absolute",
      top: 0,
      right: 0,
      transform: "translate(50%, -50%)",
      width: badgeSize,
      height: badgeSize,
      borderRadius: "50%",
      backgroundColor: "#D84444",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      [sm]: {
        width: badgeSizeSm,
        height: badgeSizeSm,
      },
    },
    badgeText: {
      fontSize: "0.375rem",
      fontWeight: 500,
      color: "white",
      [sm]: {
        fontSize: "0.4375rem",
      },
    },
    price: {
      display: "none",
      [expandBreakpoint]: {
        display: "block",
        fontSize: "1.125rem",
        lineHeight: "1.6875rem",
        fontWeight: 500,
      },
    },
  });
};

const Cart = ({ classes }) => {
  const href = links.cart;
  return (
    <Link to={href}>
      <Button href={href} className={classes.button}>
        <div className={classes.iconContainer}>
          <CartIcon className={classes.icon} />
          <div className={classes.badge}>
            <Typography className={classes.badgeText}>9</Typography>
          </div>
        </div>
        <Typography className={classes.price}>$262.50</Typography>
      </Button>
    </Link>
  );
};

export default withStyles(styles)(Cart);
