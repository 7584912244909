import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Body from "./Body/index";
import MobileLearnMore from "./MobileLearnMore";
import { getFlexBreakpoint } from "./styles";
import Container from "../../components/Container";
import MobileImage from "./MobileImage";
import DesktopImage from "./DesktopImage";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      padding: "2.125rem 0",
      [theme.breakpoints.up("md")]: {
        padding: "4rem 0",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "8.5625rem 0",
      },
    },
    container: {
      [flexBreakpoint]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  });
};

const Section3 = ({ classes }) => {
  return (
    <section className={classes.root}>
      <Container classes={{ root: classes.container }}>
        <Body />
        <MobileImage />
        <DesktopImage />
        <MobileLearnMore />
      </Container>
    </section>
  );
};

export default withStyles(styles)(Section3);
