import React from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { xsUp } from "../../../../components/constants/breakpoints";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    root: {
      marginRight: "1.1875rem",
      [md]: {
        marginRight: "1.875rem",
      },
    },
    image: {
      width: "5.5rem",
      [xsUp]: {
        width: "7.375rem",
      },
      [md]: {
        width: "10.625rem",
      },
    },
    caption: {
      fontSize: "0.6875rem",
      color: "#727272",
      textAlign: "center",
      [md]: {
        fontSize: "1.125rem",
      },
    },
  });
};

const ImageColumn = ({ classes, imageFile, caption }) => {
  const image = getImage(imageFile);
  return (
    <div className={classes.root}>
      <GatsbyImage image={image} className={classes.image} />
      <Typography className={classes.caption}>{caption}</Typography>
    </div>
  );
};

export default withStyles(styles)(ImageColumn);
