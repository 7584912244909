import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import DescriptionSection from "../../components/DescriptionSection";
import { getFlexBreakpoint } from "./styles";
import SingleFruitImage from "./SingleFruitImage";
import DoubleFruitImage from "./DoubleFruitImage";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      marginTop: "2.125rem",
      position: "relative",
      [flexBreakpoint]: {
        marginTop: "2.0781rem",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "12.625rem",
      },
    },
    container: {
      [flexBreakpoint]: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    body: {
      [flexBreakpoint]: {
        marginRight: "6.125rem",
        marginTop: "7.4375rem",
        marginBottom: "7.8125rem",
      },
    },
    text: {
      textAlign: "center",
      [flexBreakpoint]: {
        textAlign: "left",
      },
    },
  });
};

const Lead = ({ classes }) => {
  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <DescriptionSection className={classes.body}>
          <Typography className={classes.text}>
            Imagine a tropical fruit that has the capability to naturally turn
            sour fruits such as lemon and lime to taste sweet without adding
            sugar. So you can enjoy your favourite juice drink such as lemonade
            without any added sugar. Experience the magic through your tastebuds
            from about 15 min to 1 hour with less than 1 calorie per fruit.
          </Typography>
        </DescriptionSection>
        <SingleFruitImage />
        <DoubleFruitImage />
      </div>
    </section>
  );
};

export default withStyles(styles)(Lead);
