import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import Sections from "./Sections";
import Slider from "./Slider";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    root: {
      paddingTop: "2.125rem",
      paddingBottom: "9.5rem",
      [md]: {
        paddingTop: "7.5625rem",
        paddingBottom: "9.125rem",
      },
    },
    title: {
      fontWeight: 600,
      marginBottom: "2.3125rem",
      textAlign: "center",
      [md]: {
        fontSize: "3.75rem",
        marginBottom: "7.1875rem",
      },
    },
    smallTitle: {
      fontSize: "0.75rem",
      marginBottom: "0.375rem",
      display: "block",
      [md]: {
        marginBottom: 0,
        display: "inline",
        fontSize: "inherit",
      },
    },
    emphasizedTitle: {
      fontSize: "1.5625rem",
      [md]: {
        fontSize: "inherit",
      },
    },
  });
};

const HowToUseRichberry = ({ classes }) => {
  return (
    <section className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        <span className={classes.smallTitle}>HOW TO USE </span>
        <span className={classes.emphasizedTitle}>RICHBERRY</span>
      </Typography>
      <Sections />
      <Slider />
    </section>
  );
};

export default withStyles(styles)(HowToUseRichberry);
