import * as React from "react";

function SvgRightArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.975 19.194"
      {...props}
    >
      <path d="M7.666 9.594L.402 2.337A1.373 1.373 0 012.349.4l8.229 8.222a1.369 1.369 0 01.04 1.891l-8.269 8.281a1.372 1.372 0 01-1.943-1.937z" />
    </svg>
  );
}

export default SvgRightArrow;
