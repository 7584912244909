import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Star from "./Star";

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
    },
  });
};

const Stars = ({ classes, amount = 5 }) => {
  const renderedStars = [];
  for (let starIndex = 0; starIndex < amount; starIndex++) {
    renderedStars.push(<Star />);
  }
  return <div className={classes.root}>{renderedStars}</div>;
};

export default withStyles(styles)(Stars);
