import React, { useState } from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Video from "./Video";
import PlayButton from "./PlayButton/index";

const styles = () => {
  return createStyles({
    root: {
      position: "relative",
    },
  });
};

const VideoSection = ({ classes }) => {
  const [wasClicked, setWasClicked] = useState(false);
  return (
    <section className={classes.root}>
      <Video wasClicked={wasClicked} />
      {wasClicked ? null : <PlayButton setWasClicked={setWasClicked} />}
    </section>
  );
};

export default withStyles(styles)(VideoSection);
