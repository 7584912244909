import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

import SocialMedia from "./SocialMedia";
import ImageGrid from "./ImageGrid";
import PageContainer from "../../components/Container";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.light,
      paddingTop: "2.125rem",
      paddingBottom: "2.25rem",
      textAlign: "center",
      [md]: {
        paddingTop: "7.5625rem",
        paddingBottom: "8.1875rem",
      },
    },
    title: {
      marginBottom: "0.9375rem",
      [md]: {
        marginBottom: "1.5625rem",
      },
    },
    subtitle: {
      fontSize: "0.8125rem",
      fontWeight: 400,
      marginBottom: "2.125rem",
      [md]: {
        marginBottom: "7.3125rem",
        fontSize: "1.25rem",
      },
    },
    twitterHandle: {
      fontWeight: 500,
    },
    container: {
      maxWidth: "97.75rem",
      position: "relative",
    },
  });
};

const LetsBeSocial = ({ classes }) => {
  return (
    <section className={classes.root}>
      <PageContainer classes={{ root: classes.container }}>
        <Typography className={classes.title} variant="h2">
          LET’S BE SOCIAL
        </Typography>
        <Typography className={classes.subtitle}>
          Follow us on social media:
          <span className={classes.twitterHandle}>@richberry.ph</span>
        </Typography>
        <SocialMedia />
        <ImageGrid />
      </PageContainer>
    </section>
  );
};

export default withStyles(styles)(LetsBeSocial);
