import * as React from "react";

function SvgSocialMediaIllustration(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 865.326 751.543"
      {...props}
    >
      <path
        d="M865.326 365.118c0 167.2-147.112 308.146-347.852 351.416a610.692 610.692 0 01-99.027 12.938c-1.62.081-3.241.162-4.872.22q-13 .538-26.2.544-22.948 0-45.353-1.632-12.429-.885-24.65-2.257c-79.238-8.888-152.157-32.623-213.747-67.4q-11.544-6.51-22.544-13.54a449.144 449.144 0 01-38.838-27.728c-82.27-65.571-13.679-205.531 29.2-293.645C187.167 86.217 123.413.003 387.374.003s477.952 163.461 477.952 365.115z"
        fill="#d6d6d6"
      />
      <path
        d="M676.159 150.523c-82.041-1.471-82.028-123.394 0-124.853 82.041 1.471 82.028 123.394 0 124.853zM521.007 332.215c-82.041-1.471-82.028-123.393 0-124.853 82.042 1.471 82.031 123.394 0 124.853zM682.847 516.711c-82.041-1.471-82.028-123.393 0-124.853 82.041 1.472 82.028 123.397 0 124.853z"
        fill="#fff"
      />
      <path
        d="M317.077 725.48a9.662 9.662 0 01-.381-1.805l-23.824-135.863a9.856 9.856 0 00-.162-1.042 12.275 12.275 0 00-10.265-9.7l-135.875.59a12.183 12.183 0 00-12.776 6.747q-3.315 6.909-6.492 14.015-12.863 28.625-23.678 60.525c61.59 34.776 134.509 58.511 213.747 67.4z"
        fill="#262626"
      />
      <path
        d="M449.721 361.912a28.655 28.655 0 00-.651 4.465l-116.949 67.467-28.426-16.365-30.3 39.672 47.507 33.86a21.779 21.779 0 0026.13-.637l120.593-95.137a28.577 28.577 0 10-17.9-33.326z"
        fill="#ffb8b8"
      />
      <path
        d="M330.517 427.101a12.275 12.275 0 00-6.029-8.24l-53.663-30.008a34.023 34.023 0 10-41.719 53.755l42.784 44.427a12.256 12.256 0 0018.528-1.007l8.332-10.809 2.847-3.68 26.617-34.5a12 12 0 002.095-4.236 12.176 12.176 0 00.208-5.705z"
        fill="#597263"
      />
      <circle
        cx={66.865}
        cy={66.865}
        r={66.865}
        transform="rotate(-45 403.523 10.195)"
        fill="#ffb8b8"
      />
      <path
        d="M290.082 541.404c2.685-4.016 19.754-31.119 13.3-62.886a70.188 70.188 0 00-1.782-6.978 77.952 77.952 0 00-14.316-26.166 63.642 63.642 0 00-.069-6.307v-.012a60.163 60.163 0 00-55.549-56.081l-74.783-5.682a59.6 59.6 0 00-51.013 21.71 63.936 63.936 0 00-3.947 5.323.011.011 0 00-.011.012 59.5 59.5 0 00-7.708 48.871l.637 2.8 7.973 34.961 13.332 58.5 11.152 48.954 1.18 5.161a12.279 12.279 0 0011.943 9.524 11.652 11.652 0 001.991-.162l144.7-23.724a12.106 12.106 0 005.59-2.453 12.621 12.621 0 002.534-2.72 12.112 12.112 0 001.874-9.455z"
        fill="#597263"
      />
      <path
        d="M5.295 738.859a29.246 29.246 0 0111.625-43.312l82.39-253.312 47.747 22.656-90.144 246.647a29.4 29.4 0 01-51.618 27.321z"
        fill="#ffb8b8"
      />
      <path
        d="M146.501 382.396a36.464 36.464 0 00-44.578 21.93.011.011 0 00-.008 0 .011.011 0 000 .008c-.3.775-.579 1.585-.822 2.4l-6.979 14.8-21.085 44.728a13.1 13.1 0 008.066 18.146l4.109 1.238 17.613 5.323 31.258 9.443 10.415 3.148a13.115 13.115 0 0016.769-10.647l9.629-65.316a36.409 36.409 0 00-24.384-45.2z"
        fill="#597263"
      />
      <path
        d="M266.939 264.005c-23.826 20.842-60.4 25.682-88.829 11.753 12.769 15.149 37.02 89.929 37.02 89.929s-68.475-7.2-78.149-9.265c-57.141-12.219-36.371-160.2 24.516-159.456 10.313.126 19.328 1.374 26.191 9.071 4.556-12.77 19.042-20.293 32.566-19.319s25.753 9.1 34.453 19.5a74.612 74.612 0 0115.78 61.155z"
        fill="#262626"
      />
      <path
        d="M689.491 56.091c-15.391.442-45.319-5.059-45.334 18.669.441 15.39-5.06 45.318 18.668 45.333 15.39-.441 45.318 5.06 45.333-18.668-.441-15.385 5.06-45.313-18.667-45.334z"
        fill="#3c5245"
      />
      <path
        d="M693.491 74.764c-5.179.065-5.162-8.071.018-7.983 5.179-.065 5.161 8.07-.018 7.983zM676.16 77.429c13.841-.2 13.841 21.54 0 21.335-13.841.201-13.841-21.538 0-21.335zm0-5.333c-20.758-.3-20.762 32.307 0 32 20.761.3 20.758-32.307 0-32zM570.151 464.808a1.888 1.888 0 01-1.425-3.186l6.3-6.655a1.085 1.085 0 00-.042-1.532l-6.693-6.327a1.94 1.94 0 01-.076-2.742 1.945 1.945 0 012.734-.084l9 8.412a1.834 1.834 0 01.072 2.61l-8.559 8.931a1.884 1.884 0 01-1.312.58zM563.274 455.126a1.153 1.153 0 01-.752.3l-20.987.579a1.157 1.157 0 01-.064-2.313l20.987-.579a1.157 1.157 0 01.815 2.008zM408.133 280.314a1.888 1.888 0 01-1.425-3.186l6.3-6.655a1.085 1.085 0 00-.042-1.532l-6.691-6.332a1.94 1.94 0 01-.076-2.742 1.945 1.945 0 012.734-.084l9 8.412a1.834 1.834 0 01.072 2.61l-8.559 8.931a1.884 1.884 0 01-1.313.578zM401.257 270.628a1.153 1.153 0 01-.752.3l-20.987.579a1.157 1.157 0 01-.064-2.313l20.987-.579a1.157 1.157 0 01.815 2.008zM563.208 98.622a1.888 1.888 0 01-1.425-3.186l6.3-6.655a1.085 1.085 0 00-.042-1.532l-6.691-6.332a1.94 1.94 0 01-.076-2.742 1.945 1.945 0 012.734-.084l9 8.411a1.834 1.834 0 01.072 2.61l-8.559 8.931a1.884 1.884 0 01-1.313.579zM556.331 88.937a1.152 1.152 0 01-.752.3l-20.987.579a1.157 1.157 0 11-.064-2.313l20.987-.579a1.157 1.157 0 01.815 2.008zM541.721 688.436c-5.427 11.272-14.269 20.391-24.245 28.1a610.688 610.688 0 01-99.027 12.938c-1.62.081-3.241.162-4.872.22q-13 .538-26.2.544-22.948 0-45.353-1.632a36.785 36.785 0 01-.232-10.392c1.655-13.529 11.92-25.958 25.24-28.828s28.5 5.08 32.543 18.088c2.234-25.09 4.791-51.221 18.134-72.584 12.07-19.338 32.982-33.179 55.653-35.655a70.063 70.063 0 0138.734 7.094q1.65.833 3.263 1.736a64.629 64.629 0 0118.667 15.936c14.141 17.889 17.602 43.905 7.695 64.435z"
        fill="#fff"
      />
      <path
        d="M515.358 608.063a1.761 1.761 0 01-.717 1.586 366.712 366.712 0 00-96.192 119.825c-1.62.081-3.241.162-4.872.22q3.09-6.267 6.411-12.406a372.825 372.825 0 0140.4-59.8 368.118 368.118 0 0151.221-50.862 2.223 2.223 0 01.486-.3 2.373 2.373 0 013.263 1.736z"
        fill="#f2f2f2"
      />
      <path
        d="M716.742 438.536s-5.3 5.937-6.319 6.319c-2.8 30.471-21.826 37.391-37.1 37.391-10.982 0-20.032-3.576-19.731-4.56 16.294.382 17.683-6.307 17.683-6.307-9.467-1.4-11.364-7.58-11.364-7.58a3.9 3.9 0 005.046-1.261c-10.219-2.662-8.83-12.637-8.83-12.637a13.156 13.156 0 006.18 2.407c-1.134-1.389-10.693-12.846-4.942-18.783.336.9 10.288 14.454 26.918 13.471a13.369 13.369 0 0113.008-16.04c8.842 0 10.74 5.057 10.74 5.057l6.319-3.8c-.128 0-.764 6.319-3.923 7.58z"
        fill="#3c5245"
      />
      <path
        d="M555.403 269.991a34.691 34.691 0 01-29.262 34.268l.005-24.234h8.077l1.539-10.034h-9.617c-.324-8.355-.648-13.042 10.022-11.92v-8.529c-15.982-3.518-22.648 4.583-20.854 20.449h-8.807v10.033h8.807v24.222c-41.453-6.377-36.581-69.089 5.4-68.927a34.678 34.678 0 0134.684 34.679z"
        fill="#3c5245"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSocialMediaIllustration;
