import * as React from "react";

function SvgStarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.135 19.272"
      {...props}
    >
      <path d="M8.988.67L6.53 5.653l-5.5.8a1.2 1.2 0 00-.666 2.055l3.978 3.876-.941 5.476a1.2 1.2 0 001.748 1.271l4.919-2.586 4.919 2.586a1.2 1.2 0 001.746-1.268l-.941-5.476 3.978-3.876a1.2 1.2 0 00-.666-2.055l-5.5-.8L11.148.67a1.205 1.205 0 00-2.16 0z" />
    </svg>
  );
}

export default SvgStarIcon;
