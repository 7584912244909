import React from "react";

import BaseSlider from "../../../components/SliderSectionSecondary/Slider";
import Card from "./Card";

const Slider = ({ posts }) => {
  const renderPost = (post) => <Card {...post} key={post.fields.slug} />;

  const renderPostsList = () => posts.map(renderPost);

  const renderedPosts = renderPostsList();

  return <BaseSlider>{renderedPosts}</BaseSlider>;
};

export default Slider;
