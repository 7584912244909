import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const styles = () => {
  return createStyles({
    root: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
    },
  });
};

const Image = ({ classes }) => {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "hero-image" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const image = getImage(file);
  return <GatsbyImage image={image} className={classes.root} />;
};

export default withStyles(styles)(Image);
