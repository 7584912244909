import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";

import Item from "./Item";
import {
  imageWidth as defaultImageWidth,
  imageWidthMd,
  imageGap as defaultImageGap,
  imageGapMd,
} from "./styles";

const getMaxWidth = (imageWidth, gap) => `calc(${imageWidth} * 2 + ${gap})`;

const styles = (theme) => {
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      maxWidth: getMaxWidth(defaultImageWidth, defaultImageGap),
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        maxWidth: getMaxWidth(imageWidthMd, imageGapMd),
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: 0,
      },
    },
  });
};

const ImageGrid = ({ classes }) => {
  const { social1, social2, social3, social4 } = useStaticQuery(
    graphql`
      {
        social1: file(name: { eq: "social-1" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        social2: file(name: { eq: "social-2" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        social3: file(name: { eq: "social-3" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        social4: file(name: { eq: "social-4" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );
  return (
    <div className={classes.root}>
      <Item imageFile={social1} />
      <Item imageFile={social2} />
      <Item imageFile={social3} />
      <Item imageFile={social4} />
    </div>
  );
};

export default withStyles(styles)(ImageGrid);
