import { createStyles, withStyles } from "@material-ui/core";

import { getFlexBreakpoint } from "../styles";

import Image from "./Image";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      width: "10.5rem",
      [flexBreakpoint]: {
        width: "51.6%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "28.5rem",
      },
    },
  });
};

export default withStyles(styles)(Image);
