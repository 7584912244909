import { createStyles, withStyles } from "@material-ui/core";
import Stars from "../../../../components/Stars";

const styles = (theme) => {
  return createStyles({
    root: {
      marginBottom: "0.625rem",
      [theme.breakpoints.up("md")]: {
        marginTop: "1.375rem",
      },
    },
  });
};

export default withStyles(styles)(Stars);
