import React from "react";
import { IconButton, createStyles, withStyles } from "@material-ui/core";

import MenuIcon from "./MenuIcon";
import { getExpandBreakpoint } from "../styles";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      paddingRight: 0,
      [expandBreakpoint]: {
        display: "none",
      },
    },
    icon: {
      width: "0.9375rem",
      height: "0.875rem",
    },
  });
};

const ToggleDrawer = ({ classes, open }) => {
  return (
    <IconButton className={classes.root} onClick={open}>
      <MenuIcon className={classes.icon} />
    </IconButton>
  );
};

export default withStyles(styles)(ToggleDrawer);
