import * as React from "react";

function SvgPlayIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98.306 98.306"
      {...props}
    >
      <path
        d="M49.152 0a49.153 49.153 0 1049.153 49.152A49.144 49.144 0 0049.152 0zm22.931 53.91L37.201 73.927a4.765 4.765 0 01-7.076-4.162V28.537a4.768 4.768 0 017.076-4.162l34.883 21.21a4.772 4.772 0 010 8.324z"
        fill="#d8d8d8"
      />
    </svg>
  );
}

export default SvgPlayIcon;
