import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      minHeight: "14.3125rem",
      width: "100%",
      backgroundColor: "#262626",
      boxShadow: "5px 5px 10px #00000029",
      [theme.breakpoints.up("xl")]: {
        minHeight: "54.5rem",
      },
    },
  });
};

const Video = ({ classes, wasClicked }) => {
  return (
    <video
      className={classes.root}
      src="https://elearning-images-448800-prod.s3-us-west-1.amazonaws.com/video.mp4"
      controls={wasClicked}
    />
  );
};

export default withStyles(styles)(Video);
