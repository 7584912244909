import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      display: "none",
      [flexBreakpoint]: {
        display: "block",
        flexShrink: 0,
        flexGrow: 1,
        width: "50%",
        maxWidth: "12.5rem",
        zIndex: 2,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "39.5rem",
        marginBottom: "-11.0625rem",
      },
    },
  });
};

const DoubleFruitImage = ({ classes }) => {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "fruit-double" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const image = getImage(file);
  return (
    <GatsbyImage image={image} className={classes.root} objectFit="contain" />
  );
};

export default withStyles(styles)(DoubleFruitImage);
