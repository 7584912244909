import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      fontSize: "0.9375rem",
      fontWeight: 600,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.5625rem",
      },
    },
  });
};

const Title = ({ classes, ...other }) => {
  return <Typography classes={classes} variant="h1" {...other} />;
};

export default withStyles(styles)(Title);
