import * as React from "react";

function SvgChatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.715 26.281"
      {...props}
    >
      <path
        d="M23.369 20.376a1.722 1.722 0 01.235-.867 2.393 2.393 0 01.149-.22 11.517 11.517 0 001.962-6.419A12.091 12.091 0 0013.368 1a12.262 12.262 0 00-12.1 9.447 11.423 11.423 0 00-.263 2.431 12.058 12.058 0 0012.155 12.02 14.639 14.639 0 003.355-.547c.8-.22 1.6-.512 1.805-.59a1.879 1.879 0 01.661-.121 1.848 1.848 0 01.718.142l4.03 1.429a.961.961 0 00.277.071.566.566 0 00.569-.569.913.913 0 00-.036-.192z"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgChatIcon;
