import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      color: "white",
      borderColor: "white",
      borderWidth: "0.125rem",
      [theme.breakpoints.up("md")]: {
        padding: "1rem 0",
        minWidth: "16rem",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "23.8125rem",
        padding: "1.4rem 0",
        fontSize: "1.25rem",
      },
    },
  });
};

const LearnMoreButton = ({ classes }) => {
  return (
    <Button href="#" variant="outlined" classes={classes}>
      Learn More
    </Button>
  );
};

export default withStyles(styles)(LearnMoreButton);
