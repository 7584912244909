import * as React from "react";

function SvgCartIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.553 15.602"
      {...props}
    >
      <path
        d="M16.094 9.182l1.441-6.338a.731.731 0 00-.713-.893H4.852L4.572.585A.731.731 0 003.856 0H.731A.731.731 0 000 .731v.488a.731.731 0 00.731.731h2.13L5 12.416a1.707 1.707 0 102.043.261h6.389a1.706 1.706 0 101.938-.317l.168-.74a.731.731 0 00-.713-.893H6.647l-.2-.975h8.933a.731.731 0 00.714-.57z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCartIcon;
