import React from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { show3ColumnsBreakpoint } from "../../styles";

export const paddingX = "0.625rem";

const styles = (theme) => {
  const sm = theme.breakpoints.up("sm");
  const md = theme.breakpoints.up("md");
  return createStyles({
    root: {
      padding: `0 ${paddingX}`,
      [show3ColumnsBreakpoint]: {
        padding: 0,
      },
    },
    imageContainer: {
      padding: "0.625rem 0",
      border: "1px solid #8E8E8E",
      marginBottom: "0.6875rem",
      height: "9.875rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [sm]: {
        height: "15rem",
      },
      [md]: {
        padding: "1.625rem 1.8125rem",
        marginBottom: "1.3125rem",
        borderWidth: "0.125rem",
      },
      [theme.breakpoints.up("xl")]: {
        height: "29.875rem",
      },
    },
    image: {
      height: "100%",
    },
    title: {
      fontSize: "0.625rem",
      fontWeight: 500,
      marginBottom: "0.625rem",
      [sm]: {
        fontSize: "1.125rem",
        lineHeight: "1.8125rem",
      },
    },
    price: {
      fontWeight: 600,
      fontSize: "0.8125rem",
      [sm]: {
        fontSize: "1.25rem",
      },
    },
  });
};

const Product = ({ classes, title, image: imageFile, price }) => {
  const image = getImage(imageFile);
  const formattedPrice = price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <GatsbyImage
          image={image}
          objectFit="contain"
          className={classes.image}
        />
      </div>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      <Typography className={classes.price}>{formattedPrice}</Typography>
    </div>
  );
};

export default withStyles(styles)(Product);
