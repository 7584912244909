import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Body from "./Body";
import Header from "./Header";
import ImageColumn from "./ImageColumn";

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
};

const Testimonial = ({
  classes,
  title,
  content,
  name,
  product: { image, name: productName },
  date,
}) => {
  return (
    <div className={classes.root}>
      <ImageColumn caption={productName} imageFile={image} />
      <div>
        <Header date={date} />
        <Body title={title} content={content} name={name} />
      </div>
    </div>
  );
};

export default withStyles(styles)(Testimonial);
