import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      marginTop: "-3.0625rem",
      marginBottom: "-2.25rem",
      width: "15.3125rem",
      marginLeft: "auto",
      display: "block",
      zIndex: 2,
      [flexBreakpoint]: {
        display: "none",
      },
    },
  });
};

const SingleFruitImage = ({ classes }) => {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "fruit-single" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const image = getImage(file);
  return (
    <GatsbyImage image={image} className={classes.root} objectFit="contain" />
  );
};

export default withStyles(styles)(SingleFruitImage);
