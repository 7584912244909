import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import BaseSlider from "react-slick";

import Container from "../../../components/Container";
import Product, { paddingX as productPaddingX } from "./Product";
import PrevButton from "./PrevButton";
import NextButton from "./NextButton";
import {
  show3ColumnsBreakpointAmount as show3ColumnsBreakpointPixels,
  show3ColumnsBreakpoint,
} from "../styles";
import { slideSelector } from "../../../components/constants/slider";

const styles = (theme) => {
  const xl = theme.breakpoints.up("xl");
  return createStyles({
    root: {
      marginBottom: "2.125rem",
      // To remove the product's excess padding
      padding: `0 calc(2.5rem - ${productPaddingX})`,
      // Because the ViewAll button dissappears at this breakpoint
      [show3ColumnsBreakpoint]: {
        marginBottom: 0,
      },
      [xl]: {
        paddingLeft: "8.25rem",
        paddingRight: 0,
        maxWidth: "100%",
      },
      [slideSelector]: {
        [show3ColumnsBreakpoint]: {
          marginLeft: "5.5625rem",
        },
      },
    },
  });
};

const Slider = ({ classes, products }) => {
  const renderProduct = (product, index) => (
    <Product {...product} key={index} />
  );

  const renderProductsList = () => products.map(renderProduct);

  const renderedProducts = renderProductsList();

  return (
    <Container classes={{ root: classes.root }}>
      <BaseSlider
        nextArrow={<NextButton />}
        prevArrow={<PrevButton />}
        arrows={false}
        slidesToShow={3}
        slidesToScroll={3}
        centerMode
        responsive={[
          {
            breakpoint: show3ColumnsBreakpointPixels,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              centerMode: false,
              arrows: true,
            },
          },
        ]}
      >
        {renderedProducts}
      </BaseSlider>
    </Container>
  );
};

export default withStyles(styles)(Slider);
