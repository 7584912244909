import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import BaseSlider from "react-slick";

import Container from "../../Container";
import { paddingX as cardPaddingX } from "./Card/index";
import { paddingX as descriptionPaddingX } from "../../DescriptionSection";
import { show3ColumnsBreakpoint, show2ColumnsBreakpoint } from "./styles";

const getDescriptionPadding = (cardPadding) =>
  `0 calc(${descriptionPaddingX} - ${cardPadding})`;

const styles = (theme) => {
  return createStyles({
    container: {
      padding: getDescriptionPadding(cardPaddingX),
      [`@media (max-width: ${show2ColumnsBreakpoint}px)`]: {
        padding: 0,
      },
      [theme.breakpoints.up("xl")]: {
        padding: 0,
      },
      "& .slick-slide + .slick-slide > div": {
        [theme.breakpoints.up("xl")]: {
          marginLeft: "2rem",
        },
      },
    },
  });
};

const Slider = ({ children, classes }) => {
  return (
    <Container classes={{ root: classes.container }}>
      <BaseSlider
        slidesToShow={3}
        arrows={false}
        infinite
        responsive={[
          {
            breakpoint: show3ColumnsBreakpoint,
            settings: {
              slidesToShow: 2,
              centerMode: false,
            },
          },
          {
            breakpoint: show2ColumnsBreakpoint,
            settings: {
              centerMode: true,
              slidesToShow: 1,
            },
          },
        ]}
      >
        {children}
      </BaseSlider>
    </Container>
  );
};

export default withStyles(styles)(Slider);
