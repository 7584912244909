import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Links from "./Links";
import { getExpandBreakpoint } from "./styles";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      display: "none",
      [expandBreakpoint]: {
        display: "block",
      },
    },
  });
};

export default withStyles(styles)(Links);
