import React, { useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  createStyles,
  withStyles,
} from "@material-ui/core";

import LogIn from "./LogIn";
import DesktopLinks from "./DesktopLinks";
import Drawer from "./Drawer";
import Cart from "./Cart";
import ToggleDrawer from "./ToggleDrawer";
import Logo from "./Logo";

const styles = (theme) => {
  const navSection = {
    display: "flex",
    alignItems: "center",
  };
  return createStyles({
    root: {
      padding: "1.3125rem 0",
      minHeight: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    toolbar: {
      padding: 0,
      minHeight: 0,
    },
    container: {
      padding: "0 1.1875rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      [theme.breakpoints.up("xl")]: {
        maxWidth: "111.4375rem",
      },
    },
    left: navSection,
    right: navSection,
  });
};

const Navigation = ({ classes }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);

  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <AppBar className={classes.root} position="static">
        <Toolbar className={classes.toolbar}>
          <Container className={classes.container}>
            <div className={classes.left}>
              <Logo />
              <DesktopLinks />
            </div>
            <div className={classes.right}>
              <LogIn />
              <Cart />
              <ToggleDrawer open={openDrawer} />
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer isOpen={isDrawerOpen} close={closeDrawer} />
    </>
  );
};

export default withStyles(styles)(Navigation);
