import { createStyles, withStyles } from "@material-ui/core";

import ViewAllButton from "../../components/ViewAllButton";
import { show3ColumnsBreakpoint } from "./styles";

const styles = () => {
  return createStyles({
    root: {
      [show3ColumnsBreakpoint]: {
        display: "none",
      },
    },
  });
};

export default withStyles(styles)(ViewAllButton);
