import * as React from "react";

function SvgUserIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.925 17.925"
      {...props}
    >
      <path
        d="M8.963 8.963a4.481 4.481 0 10-4.481-4.481 4.48 4.48 0 004.481 4.481zm0 2.241C5.971 11.2 0 12.7 0 15.685v2.241h17.925v-2.241c0-2.985-5.971-4.485-8.962-4.485z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgUserIcon;
