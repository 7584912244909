import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import BaseCard, {
  Image,
  Body,
} from "../../../../components/SliderSectionSecondary/Slider/Card";
import ReadMore from "./ReadMore";
import { getShowButtonBreakpoint } from "./styles";

const styles = (theme) => {
  const readMoreBreakpoint = getShowButtonBreakpoint(theme);
  return createStyles({
    description: {
      [readMoreBreakpoint]: {
        marginBottom: "2.0719rem",
      },
    },
    title: {
      [theme.breakpoints.up("md")]: {
        fontWeight: 600,
      },
    },
  });
};

const Card = ({ classes, title, excerpt, image, fields: { slug } }) => {
  return (
    <BaseCard slug={slug}>
      <Image imageFile={image} />
      <Body
        title={title}
        description={excerpt}
        classes={{ title: classes.title, description: classes.description }}
      >
        <ReadMore slug={slug} />
      </Body>
    </BaseCard>
  );
};

export default withStyles(styles)(Card);
