import React from "react";
import { Button, createStyles, withStyles } from "@material-ui/core";
import { Link } from "gatsby";

import { getExpandBreakpoint } from "../styles";
import { links } from "../../../constants/links";
import UserIcon from "./UserIcon";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  const iconSize = "1.125rem";
  return createStyles({
    root: {
      textDecoration: "none",
      display: "none",
      [expandBreakpoint]: {
        display: "block",
      },
    },
    button: {
      color: "white",
      minWidth: 0,
      paddingTop: 0,
      paddingBottom: 0,
      [expandBreakpoint]: {
        paddingRight: "1.7969rem",
        borderRight: "1px solid #BCBCBC",
      },
    },
    icon: {
      width: iconSize,
      height: iconSize,
      marginLeft: "1.1875rem",
    },
  });
};

const LogIn = ({ classes }) => {
  const href = links.login;
  return (
    <Link className={classes.root} to={href}>
      <Button className={classes.button} href={href}>
        Log In
        <UserIcon className={classes.icon} />
      </Button>
    </Link>
  );
};

export default withStyles(styles)(LogIn);
