import React from "react";
import { createStyles, withStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = () => {
  const iconSize = "1.5rem";
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-end",
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
    button: {
      paddingRight: 0,
    },
  });
};

const Close = ({ classes, close }) => {
  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={close}>
        <CloseIcon className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(Close);
