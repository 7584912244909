import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Container from "../../components/Container";
import ShopLink from "./ShopLink";
import { getExpandBreakpoint, expandBreakpoint } from "./styles";

const styles = (theme) => {
  const expandBreakpointUp = getExpandBreakpoint(theme);
  const paddingYMd = "7.5625rem";
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.light,
      paddingTop: "2.125rem",
      paddingBottom: "2.25rem",
      [theme.breakpoints.up("md")]: {
        paddingTop: paddingYMd,
        paddingBottom: paddingYMd,
      },
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      maxWidth: "18.5rem",
      [theme.breakpoints.up(expandBreakpoint)]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "space-between",
        maxWidth: "97rem",
      },
    },
  });
};

const ShopLinks = ({ classes }) => {
  const link = <ShopLink>Shop Link</ShopLink>;
  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {link}
        {link}
        {link}
        {link}
      </Container>
    </section>
  );
};

export default withStyles(styles)(ShopLinks);
