import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import BaseSlider from "../../../components/SliderSectionSecondary/Slider";
import Card from "./Card";

const Slider = () => {
  const { recipe1, recipe2, recipe3 } = useStaticQuery(graphql`
    {
      recipe1: file(name: { eq: "recipe-1" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      recipe2: file(name: { eq: "recipe-2" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      recipe3: file(name: { eq: "recipe-3" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <BaseSlider>
      <Card
        title="Name of recipe"
        description="Lorem ipsum dolor sit amet, consts adip elit filts."
        image={recipe1}
      />
      <Card
        title="Name of recipe"
        description="Lorem ipsum dolor sit amet, consts adip elit filts."
        image={recipe2}
      />
      <Card
        title="Name of recipe"
        description="Lorem ipsum dolor sit amet, consts adip elit filts."
        image={recipe3}
      />
    </BaseSlider>
  );
};

export default Slider;
