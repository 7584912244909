import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import BaseSlider from "react-slick";

import Container from "../../components/Container";
import Steps from "./Steps";
import { getExpandBreakpoint } from "./styles";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      [expandBreakpoint]: {
        display: "none",
      },
    },
  });
};

const Slider = ({ classes }) => {
  const ConfiguredSlider = (props) => (
    <BaseSlider arrows={false} slidesToShow={1} dots {...props}></BaseSlider>
  );
  return (
    <Container className={classes.root}>
      <Steps Container={ConfiguredSlider} />
    </Container>
  );
};

export default withStyles(styles)(Slider);
