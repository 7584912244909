import React from "react";
import { IconButton, createStyles, withStyles } from "@material-ui/core";

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    icon: {
      width: "0.6875rem",
      height: "1.1875rem",
    },
  });
};

const SlideArrow = ({ classes, Icon, className, ...other }) => {
  const combinedClasses = `${classes.root} ${className}`;
  return (
    <IconButton className={combinedClasses} {...other}>
      <Icon className={classes.icon} />
    </IconButton>
  );
};

export default withStyles(styles)(SlideArrow);
