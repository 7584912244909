import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import Body from "./Body";
import Image from "./Image";
import { getExpandBreakpoint } from "../../styles";

const styles = (theme) => {
  const sm = theme.breakpoints.up("sm");
  const md = theme.breakpoints.up("md");
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      listStyle: "none",
      display: "flex",
      flexDirection: "column",
      [expandBreakpoint]: {
        marginBottom: "2rem",
      },
      [sm]: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: 0,
      },
    },
    content: {
      display: "flex",
      alignItems: "flex-start",
      [sm]: {
        alignItems: "center",
      },
    },
    stepNumber: {
      fontWeight: 600,
      fontSize: "5rem",
      marginRight: "1.0625rem",
      display: "block",
      lineHeight: 1,
      [md]: {
        lineHeight: "6.6875rem",
        fontSize: "12.5rem",
        marginRight: "3.3125rem",
      },
    },
  });
};

const Step = ({ classes, stepNumber, title, description, imageFile }) => {
  return (
    <li className={classes.root}>
      <Image imageFile={imageFile} stepNumber={stepNumber} />
      <div className={classes.content}>
        <Typography className={classes.stepNumber}>{stepNumber}</Typography>
        <Body title={title} description={description} />
      </div>
    </li>
  );
};

export default withStyles(styles)(Step);
