import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { getFlexBreakpoint } from "../../Home/FeaturedRecipes/styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      borderBottom: "2px solid black",
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 0,
      display: "none",
      [flexBreakpoint]: {
        display: "block",
      },
    },
  });
};

const ViewAll = ({ classes }) => {
  return <Button classes={classes}>View All</Button>;
};

export default withStyles(styles)(ViewAll);
