import { createStyles, withStyles } from "@material-ui/core";
import { getFlexBreakpoint } from "../styles";

import Image from "./Image";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      zIndex: 3,
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "13.5625rem",
      [flexBreakpoint]: {
        width: "66.7%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "36.625rem",
      },
    },
  });
};

export default withStyles(styles)(Image);
