import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";

import PlayIcon from "./PlayIcon";

const styles = (theme) => {
  const iconSize = "2.7981rem";
  const iconSizeLg = "6.1444rem";
  return createStyles({
    root: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      width: iconSize,
      height: iconSize,
      [theme.breakpoints.up("md")]: {
        width: iconSizeLg,
        height: iconSizeLg,
      },
    },
  });
};

const PlayButton = ({ classes, setWasClicked }) => {
  const handleClick = () => {
    const video = document.querySelector("video");
    if (video.paused) {
      video.play();
      setWasClicked(true);
    }
  };

  return (
    <Button onClick={handleClick} className={classes.root}>
      <PlayIcon className={classes.icon} />
    </Button>
  );
};

export default withStyles(styles)(PlayButton);
