import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const styles = (theme) => {
  return createStyles({
    root: {
      position: "relative",
    },
    image: {
      height: "10.8125rem",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        height: "21.3125rem",
      },
    },
  });
};

const Image = ({ classes, imageFile, children }) => {
  const image = getImage(imageFile);
  return (
    <div className={classes.root}>
      <GatsbyImage className={classes.image} image={image} />
      {children}
    </div>
  );
};

export default withStyles(styles)(Image);
