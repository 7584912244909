import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import Container from "../Container";

const styles = (theme) => {
  return createStyles({
    root: {
      textAlign: "center",
      marginBottom: "2.125rem",
      [theme.breakpoints.up("xl")]: {
        padding: 0,
      },
    },
  });
};

const Header = ({ classes, children }) => {
  return <Container classes={classes}>{children}</Container>;
};

export default withStyles(styles)(Header);
