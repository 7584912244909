import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";

const styles = (theme) => {
  const paddingYMd = "1rem";
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    button: {
      [theme.breakpoints.up("sm")]: {
        minWidth: "12rem",
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: paddingYMd,
        paddingBottom: paddingYMd,
        minWidth: "15rem",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "29.5rem",
        padding: "1.5rem 0",
        fontSize: "1.25rem",
        fontWeight: 500,
      },
    },
  });
};

const SectionButton = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      <Button variant="contained" color="secondary" className={classes.button}>
        {children}
      </Button>
    </div>
  );
};

export default withStyles(styles)(SectionButton);
