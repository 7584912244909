import * as React from "react";

function SvgMenuIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.133 15.681"
      {...props}
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M1 7.841h15.133M1 1h15.132M1 14.681h15.132" />
      </g>
    </svg>
  );
}

export default SvgMenuIcon;
