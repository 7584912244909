import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import ViewAllHeader from "../../../components/ViewAllHeader";
import ViewAll from "../../../components/ViewAllHeader/ViewAll";

const styles = (theme) => {
  return createStyles({
    root: {
      marginBottom: "2.125rem",
      [theme.breakpoints.up("xl")]: {
        marginBottom: "4.375rem",
      },
    },
  });
};

const Header = ({ classes }) => {
  return (
    <ViewAllHeader ViewAll={ViewAll} title="OUR PRODUCTS" classes={classes} />
  );
};

export default withStyles(styles)(Header);
