import { createStyles, withStyles } from "@material-ui/core";

import ViewAllButton from "../../components/ViewAllButton";
import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      [flexBreakpoint]: {
        display: "none",
      },
    },
  });
};

export default withStyles(styles)(ViewAllButton);
