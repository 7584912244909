import React from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";

import Stars from "../../../../components/Stars";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.625rem",
      alignItems: "center",
      [md]: {
        marginBottom: "1.4375rem",
      },
    },
    date: {
      fontSize: "0.6875rem",
      fontWeight: "normal",
      marginLeft: "0.5rem",
      [md]: {
        fontSize: "0.9375rem",
      },
    },
  });
};

const Header = ({ classes, date: dateString }) => {
  const getFormattedDate = () => {
    const date = new Date(dateString);
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const formattedDate = getFormattedDate();

  return (
    <div className={classes.root}>
      <Stars />
      <Typography className={classes.date}>{formattedDate}</Typography>
    </div>
  );
};

export default withStyles(styles)(Header);
