import React from "react";
import { Typography } from "@material-ui/core";

import BaseHeader from "../../components/SliderSectionSecondary/Header";

const Header = () => {
  return (
    <BaseHeader>
      <Typography variant="h2">Latest From Our Blog</Typography>
    </BaseHeader>
  );
};

export default Header;
