import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import NavItem from "./NavItem";
import { getExpandBreakpoint } from "../styles";
import { links } from "../../../constants/links";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      marginBottom: "2rem",
      [expandBreakpoint]: {
        marginBottom: 0,
        display: "flex !important",
      },
    },
  });
};

const Links = ({ classes }) => {
  return (
    <div className={classes.root}>
      <NavItem href={links.home}>Home</NavItem>
      <NavItem hreef={links.about}>About</NavItem>
      <NavItem href={links.recipes}>Recipes</NavItem>
      <NavItem href={links.shop}>Shop</NavItem>
      <NavItem href={links.blog}>Blog</NavItem>
      <NavItem href={links.contact}>Contact</NavItem>
    </div>
  );
};

export default withStyles(styles)(Links);
