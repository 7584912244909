import React from "react";
import { createStyles, withStyles, Container } from "@material-ui/core";

import Title from "./Title";
import EmphasizedTitle from "./EmphasizedTitle";
import LearnMoreButton from "./LearnMoreButton";
import Images from "./Images/index";
import { getFlexBreakpoint, getShowButtonsBreakpoint } from "./styles";
import RewardsButton from "../../components/RewardsButton";
import SupportButton from "./SupportButton";
import Navigation from "../../components/Layout/Navigation";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  const showButtonsBreakpoint = getShowButtonsBreakpoint(theme);
  const paddingY = {
    md: "3rem",
    lg: "5rem",
    xl: 0,
  };
  return createStyles({
    root: {
      paddingBottom: "2.125rem",
      background:
        "transparent linear-gradient(180deg, #722627 0%, #B54143 100%) 0% 0% no-repeat padding-box",
      color: "white",
      position: "relative",
      [flexBreakpoint]: {
        paddingBottom: "6.25rem",
      },
    },
    container: {
      padding: "0 1.125rem",
      textAlign: "center",
      [showButtonsBreakpoint]: {
        marginBottom: "1.9375rem",
      },
      [flexBreakpoint]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
        marginTop: "2rem",
        paddingTop: paddingY.md,
        paddingBottom: paddingY.md,
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: paddingY.lg,
        paddingBottom: paddingY.lg,
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: paddingY.xl,
        paddingBottom: paddingY.xl,
        paddingLeft: "4.625rem",
        maxWidth: "97.875rem",
        marginTop: 0,
        marginBottom: 0,
      },
    },
    content: {
      [theme.breakpoints.up("xl")]: {
        maxWidth: "31.3125rem",
      },
    },
    body: {
      marginBottom: "2.125rem",
      [flexBreakpoint]: {
        marginBottom: "2.8125rem",
      },
    },
  });
};

const Hero = ({ classes }) => {
  return (
    <section className={classes.root}>
      <Navigation />
      <Container className={classes.container}>
        <Images />
        <div className={classes.content}>
          <div className={classes.body}>
            <Title>When life gives you</Title>
            <EmphasizedTitle>Richberry,</EmphasizedTitle>
            <Title>make some</Title>
            <EmphasizedTitle>Lemonade.</EmphasizedTitle>
            <Title>It'll make it sweet without adding sugar.</Title>
          </div>
          <LearnMoreButton />
        </div>
      </Container>
      <RewardsButton />
      <SupportButton />
    </section>
  );
};

export default withStyles(styles)(Hero);
