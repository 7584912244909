import React from "react";

import Header from "./Header";
import SliderSectionSecondary from "../../components/SliderSectionSecondary";
import Slider from "./Slider";
import ViewAllButton from "./ViewAllButton";

const FeaturedRecipes = () => {
  return (
    <SliderSectionSecondary>
      <Header />
      <Slider />
      <ViewAllButton />
    </SliderSectionSecondary>
  );
};

export default FeaturedRecipes;
