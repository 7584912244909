import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Step from "./Step";

const Steps = ({ Container = React.Fragment }) => {
  const { step1, step2, step3, step4, step5 } = useStaticQuery(
    graphql`
      {
        step1: file(name: { eq: "step-1" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        step2: file(name: { eq: "step-2" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        step3: file(name: { eq: "step-3" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        step4: file(name: { eq: "step-4" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        step5: file(name: { eq: "step-5" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );
  return (
    <Container>
      <Step
        stepNumber={1}
        title="CLEANSE YOUR PALATE"
        description={
          <>
            Miraculin works best with clean taste buds, so{" "}
            <strong>cleanse your palate</strong> with a little water before
            digging in.
          </>
        }
        imageFile={step1}
      />
      <Step
        stepNumber={2}
        title="TAKE 1 - 2 SERVINGS"
        description={
          <>
            <strong>Take 1-2</strong> servings of miracle berry halves from the
            package (depending on how much sweet lovin’ you can handle) and
            place it on your tongue. DItch the pit if necessary as it may taste
            bitter.
          </>
        }
        imageFile={step2}
      />
      <Step
        stepNumber={3}
        title="CHEW FOR 30 SECONDS"
        description={
          <>
            Slowly <strong>chew for about 30 seconds</strong> nibbling with your
            front teeth if you can. Try moving the fleshy part of the berry
            around your mouth while you chew to spread those flavours far and
            wide.
          </>
        }
        imageFile={step3}
      />
      <Step
        stepNumber={4}
        title="SWEETNESS DURATION"
        description={
          <>
            The sweetness-modifying effect{" "}
            <strong>may last somewhere between 15-60 minutes</strong>, depending
            on how long your mouth played with the miracle berry and the food’s
            acid concentration.
          </>
        }
        imageFile={step4}
      />
      <Step
        stepNumber={5}
        title="CHEW ONE EXTRA SERVING TO EXTEND THE SWEETNESS"
        description={
          <>
            <strong>
              Take 1 -2 additional serving to extend the sweetness.
            </strong>{" "}
            Enjoy the richberry experience.
          </>
        }
        imageFile={step5}
      />
    </Container>
  );
};

export default Steps;
