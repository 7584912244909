import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import DescriptionSection from "../../components/DescriptionSection";
import LearnMoreButton from "./LearnMoreButton";
import Image from "./Image";
import DesktopImage from "./DesktopImage";
import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.light,
      paddingBottom: "2.125rem",
      position: "relative",
      [theme.breakpoints.up("xl")]: {
        paddingBottom: "5.625rem",
      },
    },
    container: {
      [flexBreakpoint]: {
        display: "flex",
        justifyContent: "space-between",
        padding: "2rem",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "0 4.25rem",
      },
    },
    title: {
      marginBottom: "0.625rem",
      [theme.breakpoints.up("xl")]: {
        marginBottom: "1.25rem",
      },
    },
    body: {
      [flexBreakpoint]: {
        width: "50%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "auto",
      },
    },
    textContainer: {
      textAlign: "center",
      marginBottom: "2.125rem",
      [flexBreakpoint]: {
        textAlign: "left",
        padding: 0,
        marginTop: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "4rem",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "7.6875rem",
        marginBottom: "3.3125rem",
      },
    },
    text: {
      [flexBreakpoint]: {
        fontWeight: 400,
      },
    },
  });
};

const MiracleBerry = ({ classes }) => {
  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <Image />
        <DesktopImage />
        <div className={classes.body}>
          <DescriptionSection classes={{ root: classes.textContainer }}>
            <Typography variant="h2" className={classes.title}>
              Miracle Berry
            </Typography>
            <Typography className={classes.text}>
              Miracle berry is a tropical plant known for its berry (synsepalum
              dulcificum), when consumed, causes sour foods (such as lemons and
              limes) to taste sweet without any added sugar. This effect is due
              to an active ingredient taste-modifying glycoprotein called
              miraculin. The berry may be used as a low-calorie sweetener to
              mostly sour foods to make low-calorie desserts and fruit juices
              sweet.
            </Typography>
          </DescriptionSection>
          <LearnMoreButton />
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles)(MiracleBerry);
