import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import FloatingButton from "../../../components/FloatingButton";
import ChatIcon from "./ChatIcon";
import { getShowButtonsBreakpoint } from "../styles";

const styles = (theme) => {
  const showButtonsBreakpoint = getShowButtonsBreakpoint(theme);
  const backgroundColor = "#313131";
  return createStyles({
    root: {
      display: "none",
      "&:hover": {
        backgroundColor,
      },
      [showButtonsBreakpoint]: {
        backgroundColor,
        color: "white",
        right: 0,
        top: "auto",
        display: "block",
      },
    },
  });
};

const SupportButton = ({ classes }) => {
  return (
    <FloatingButton Icon={ChatIcon} classes={classes}>
      Need Help?
    </FloatingButton>
  );
};

export default withStyles(styles)(SupportButton);
