import * as React from "react";

function SvgGiftIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.593 27.562"
      {...props}
    >
      <path
        d="M23.625 6.398h-5.187a3.615 3.615 0 001.249-2.707A3.794 3.794 0 0015.805 0a3.432 3.432 0 00-3.008 1.686A3.432 3.432 0 009.788 0a3.794 3.794 0 00-3.882 3.691 3.593 3.593 0 001.249 2.707H1.969A1.967 1.967 0 000 8.367v1.23a.247.247 0 00.246.246h25.1a.247.247 0 00.246-.246v-1.23a1.967 1.967 0 00-1.967-1.969zm-7.82-4.675a2.022 2.022 0 012.073 1.969 2.022 2.022 0 01-2.073 1.968h-2.073c0-2.953.929-3.937 2.073-3.937zm-6.017 0c1.144 0 2.073.984 2.073 3.937H9.788a2.022 2.022 0 01-2.073-1.969 2.022 2.022 0 012.073-1.968zM.984 11.935v13.658a1.974 1.974 0 001.969 1.969h8.982V11.443H1.476a.494.494 0 00-.492.492zM24.117 11.443H13.658v16.119h8.982a1.974 1.974 0 001.969-1.969V11.935a.494.494 0 00-.492-.492z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgGiftIcon;
