import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { getFlexBreakpoint } from "./styles";

const styles = (theme) => {
  const flexBreakpoint = getFlexBreakpoint(theme);
  return createStyles({
    root: {
      display: "none",
      [flexBreakpoint]: {
        display: "block",
        width: "50%",
        flexShrink: 0,
        flexGrow: 1,
        marginRight: "1rem",
        zIndex: 2,
        marginBottom: 0,
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "4.5625rem",
        width: "42.125rem",
        transform: "translateY(-4.6875rem)",
      },
    },
  });
};

const SingleFruitImage = ({ classes }) => {
  const { file } = useStaticQuery(graphql`
    {
      file(name: { eq: "fruit-with-leaf-desktop" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const image = getImage(file);
  return (
    <GatsbyImage image={image} className={classes.root} objectFit="contain" />
  );
};

export default withStyles(styles)(SingleFruitImage);
