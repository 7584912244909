import React from "react";
import { createStyles, withStyles, Container } from "@material-ui/core";

import Steps from "./Steps";
import { getExpandBreakpoint } from "./styles";

const styles = (theme) => {
  const expandBreakpoint = getExpandBreakpoint(theme);
  return createStyles({
    root: {
      display: "none",
      [expandBreakpoint]: {
        display: "block",
        maxWidth: "78.125rem",
        padding: "0 2rem",
      },
      [theme.breakpoints.up("lg")]: {
        padding: 0,
      },
    },
  });
};

const Sections = ({ classes }) => {
  return (
    <Container className={classes.root}>
      <Steps />
    </Container>
  );
};

export default withStyles(styles)(Sections);
