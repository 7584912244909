import React from "react";
import { createStyles, withStyles, Button } from "@material-ui/core";
import { getShowButtonsBreakpoint } from "../Home/Hero/styles";

const styles = (theme) => {
  const iconSize = "0.9375rem";
  const iconSizeMd = "1.625rem";
  const showButtonsBreakpoint = getShowButtonsBreakpoint(theme);
  return createStyles({
    root: {
      boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.16)",
      padding: "1rem 2.25rem",
      fontSize: "0.8125rem",
      fontWeight: "normal",
      minWidth: 0,
      [showButtonsBreakpoint]: {
        position: "fixed",
        bottom: 0,
        zIndex: 4,
        minWidth: "12rem",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "13rem",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "1.3125rem 0",
        minWidth: "16.75rem",
        fontSize: "1.25rem",
      },
    },
    icon: {
      marginRight: "0.5rem",
      width: iconSize,
      height: iconSize,
      [theme.breakpoints.up("md")]: {
        marginRight: "0.8125rem",
        width: iconSizeMd,
        height: iconSizeMd,
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
};

const FloatingButton = ({ classes, Icon, children }) => {
  return (
    <Button className={classes.root}>
      <div className={classes.content}>
        <Icon className={classes.icon} />
        {children}
      </div>
    </Button>
  );
};

export default withStyles(styles)(FloatingButton);
