import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      padding: "2.125rem 0",
      [theme.breakpoints.up("md")]: {
        paddingTop: "7.5625rem",
        paddingBottom: "8.125rem",
      },
    },
  });
};

const SliderSectionPrimary = ({ classes, children }) => {
  return <section className={classes.root}>{children}</section>;
};

export default withStyles(styles)(SliderSectionPrimary);
