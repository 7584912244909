import React from "react";
import { createStyles, withStyles } from "@material-ui/core";

import FloatingButton from "../FloatingButton";
import GiftIcon from "./GiftIcon";

const styles = (theme) => {
  return createStyles({
    root: {
      position: "fixed",
      backgroundColor: "rgba(96, 23, 24, 1)",
      color: "white",
      bottom: 0,
      zIndex: 4,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  });
};

const RewardsButton = ({ classes }) => {
  return (
    <FloatingButton Icon={GiftIcon} classes={classes}>
      Rewards
    </FloatingButton>
  );
};

export default withStyles(styles)(RewardsButton);
