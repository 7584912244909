import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import BaseSlider from "react-slick";

import Container from "../../../components/Container";
import Testimonial from "./Testimonial";
import { slideSelector } from "../../../components/constants/slider";
import { show3ColumnsBreakpoint } from "./styles";

const styles = () => {
  return createStyles({
    root: {
      [slideSelector]: {
        [`@media (min-width: ${show3ColumnsBreakpoint}px)`]: {
          marginLeft: "7.3125rem",
        },
      },
    },
  });
};

const Slider = ({ classes, testimonials }) => {
  const renderTestimonial = (testimonial, index) => (
    <Testimonial {...testimonial} key={index} />
  );

  const renderTestimonialsList = () => testimonials.map(renderTestimonial);

  const renderedTestimonials = renderTestimonialsList();

  return (
    <Container classes={classes}>
      <BaseSlider
        dots
        slidesToShow={3}
        arrows={false}
        responsive={[
          {
            breakpoint: show3ColumnsBreakpoint,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {renderedTestimonials}
      </BaseSlider>
    </Container>
  );
};

export default withStyles(styles)(Slider);
