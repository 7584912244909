import React from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";

const styles = (theme) => {
  return createStyles({
    root: {
      position: "absolute",
      top: "0.75rem",
      right: "0.9375rem",
      backgroundColor: "white",
      padding: "0.375rem 1.25rem",
      borderRadius: "1.5rem",
      [theme.breakpoints.up("md")]: {
        padding: "0.625rem 2.1875rem",
      },
    },
    text: {
      fontSize: "0.5625rem",
      fontWeight: 500,
      lineHeight: "1.3125rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
  });
};

const Time = ({ classes, children }) => {
  return (
    <span className={classes.root}>
      <Typography className={classes.text}>{children}</Typography>
    </span>
  );
};

export default withStyles(styles)(Time);
