import React from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";

const styles = (theme) => {
  const md = theme.breakpoints.up("md");
  return createStyles({
    title: {
      fontSize: "0.8125rem",
      fontWeight: 600,
      marginBottom: "0.625rem",
      textTransform: "none",
      [md]: {
        fontSize: "1.5625rem",
        marginBottom: "0.9375rem",
      },
    },
    content: {
      fontSize: "0.6875rem",
      lineHeight: "1.375rem",
      marginBottom: "0.625rem",
      fontWeight: "normal",
      [md]: {
        lineHeight: "1.875rem",
        marginBottom: "0.9437rem",
        fontSize: "1.125rem",
      },
    },
    author: {
      fontSize: "0.8125rem",
      fontWeight: 500,
      [md]: {
        fontSize: "1.25rem",
      },
    },
  });
};

const Body = ({ classes, title, content, name }) => {
  return (
    <div>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
      <Typography className={classes.content} paragraph>
        {content}
      </Typography>
      <Typography className={classes.author}>{name}</Typography>
    </div>
  );
};

export default withStyles(styles)(Body);
